/**
 * Stolen and adapted from stackoverflow.com/questions/21961839/simulation-background-size-cover-in-canvas#45894506
 *
 * @param parent {{width: Number, height: Number}} Bounding box of where the image should go
 * @param child {{width: Number, height: Number}} Bounding box of image
 * @param context {CanvasRenderingContext2D} Context to transform
 * @param mode {'contain'|'cover'} Fitting mode. Equivalent to the CSS property object-fit.
 * @returns {CanvasRenderingContext2D} Transformed context
 */
export function scaleForFit (parent, child, context, mode) {
  const childWidth = child.width
  const childHeight = child.height
  const parentWidth = parent.width
  const parentHeight = parent.height

  const childRatio = childWidth / childHeight
  const parentRatio = parentWidth / parentHeight
  let width = parentWidth
  let height = parentHeight
  let scaleFactor

  let condition
  if (mode === 'contain') {
    condition = childRatio > parentRatio
  } else if (mode === 'cover') {
    condition = childRatio < parentRatio
  }
  if (condition) {
    height = width / childRatio
    scaleFactor = height / childHeight
  } else {
    width = height * childRatio
    scaleFactor = width / childWidth
  }

  context.translate((parentWidth - width) * 0.5, (parentHeight - height) * 0.5)
  context.scale(scaleFactor, scaleFactor)
  return context
}
