/**
 * Equivalent to Underscore.js's debounce
 * @see https://underscorejs.org/#debounce
 *
 * @param func Function to debounce
 * @param delay Debounce delay in ms
 */
export function debounce(func, delay) {
    var timeoutHandle;
    // @ts-ignore
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(function () {
            return func.apply(void 0, args);
        }, delay);
    };
}
