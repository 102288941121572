import * as klaro from 'klaro';
import ee from 'event-emitter';
import config from './klaro-config';
import './index.scss';
var consentChangedEvents = ee();
var manager = klaro.getManager(config);
export function setup(customizer, container) {
    if (customizer === void 0) { customizer = function (t) { return t; }; }
    if (container === void 0) { container = null; }
    var customizedConfig = customizer(config) || config;
    if (container == null)
        klaro.setup(customizedConfig);
    else {
        customizedConfig.embedded = true;
        klaro.show(customizedConfig);
        var modal = document.getElementById('klaro');
        container.appendChild(modal);
    }
}
setupWatcher();
setupPrivacyLinkListener();
export function onServiceUpdate(service, callback) {
    consentChangedEvents.on(service, callback);
    triggerInitialConsentIfApplicable(service, callback);
}
export function hasConsent(service) {
    var stored = manager.loadConsents();
    return stored[service];
}
function triggerInitialConsentIfApplicable(service, callback) {
    if (service === '*') {
        var stored = manager.loadConsents();
        var entries = Object.entries(stored);
        var consentedServices = entries.filter(function (entry) {
            var consented = entry[1];
            return consented;
        }).map(function (entry) { return entry[0]; });
        consentedServices.forEach(function (service) {
            callback({ service: service, consented: true });
        });
    }
    else if (hasConsent(service)) {
        callback({ service: service, consented: true });
    }
}
function serviceUpdate(service, consented) {
    var event = {
        consented: consented,
        service: service
    };
    consentChangedEvents.emit(service, event);
    consentChangedEvents.emit('*', event);
}
function setupWatcher() {
    manager.watch({
        update: function (object, name, data) {
            if (!data)
                return;
            if (!data.consents)
                return;
            var entries = Object.entries(data.consents);
            entries.forEach(function (_a) {
                var service = _a[0], consented = _a[1];
                serviceUpdate(service, consented);
            });
        }
    });
}
function setupPrivacyLinkListener() {
    document.querySelectorAll('.open-privacy').forEach(function (link) {
        link.addEventListener('click', function (event) {
            event.preventDefault();
            klaro.show(config);
        });
    });
}
