var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import loadScript from '../../../common/loadScript';
var facebookMounted = false;
var FacebookService = /** @class */ (function () {
    function FacebookService() {
    }
    FacebookService.prototype.login = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mountFacebookScriptIfRequired()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                try {
                                    FB.login(function (response) {
                                        if (response.authResponse) {
                                            resolve(response);
                                        }
                                        else {
                                            reject(response);
                                        }
                                    });
                                }
                                catch (error) {
                                    reject(error);
                                }
                            })];
                }
            });
        });
    };
    /**
     * @returns False if the user canceled or an error occured
     */
    FacebookService.prototype.shareDialog = function (shareDetails) {
        return __awaiter(this, void 0, void 0, function () {
            var hashtag, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mountFacebookScriptIfRequired()
                        // Unfortunately, Facebook does not support multiple hashtags
                    ];
                    case 1:
                        _a.sent();
                        hashtag = FacebookService.getSingularHashtag(shareDetails.hashtags);
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.fbUi({
                                method: 'share',
                                href: shareDetails.url,
                                hashtag: hashtag
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 4:
                        error_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FacebookService.prototype.forceScrape = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mountFacebookScriptIfRequired()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.fbApi('/', 'POST', {
                                scrape: true,
                                id: url
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FacebookService.prototype.fbUi = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        try {
                            FB.ui(options, function (response) {
                                if (response == null || ('error_code' in response)) {
                                    reject(response);
                                }
                                else {
                                    resolve(response);
                                }
                            });
                        }
                        catch (error) {
                            reject(error);
                        }
                    })];
            });
        });
    };
    FacebookService.prototype.fbApi = function (url, method, params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        try {
                            FB.api(url, method, params, function (response) {
                                if (!response || response.error) {
                                    reject(response.error);
                                }
                                else {
                                    resolve(response);
                                }
                            });
                        }
                        catch (error) {
                            reject(error);
                        }
                    })];
            });
        });
    };
    FacebookService.getSingularHashtag = function (hashtags) {
        if (hashtags == null || hashtags.length == 0)
            return '';
        else
            return '#' + hashtags[0];
    };
    FacebookService.prototype.mountFacebookScriptIfRequired = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (facebookMounted)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.mountFacebookScript()];
                    case 1:
                        _a.sent();
                        facebookMounted = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    FacebookService.prototype.mountFacebookScript = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ;
                        window.fbAsyncInit = function () {
                            FB.init({
                                appId: CONFIG.services.facebook.appId,
                                autoLogAppEvents: true,
                                xfbml: true,
                                version: 'v10.0'
                            });
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, loadScript('https://connect.facebook.net/en_US/sdk.js', function (e) {
                                e.setAttribute('crossorigin', 'anonymous');
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        throw new FacebookBlockedError(error_2);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return FacebookService;
}());
export default FacebookService;
var FacebookBlockedError = /** @class */ (function (_super) {
    __extends(FacebookBlockedError, _super);
    function FacebookBlockedError(origin) {
        var _this = _super.call(this, 'Facebook konnte nicht geladen werden') || this;
        _this.origin = origin;
        return _this;
    }
    return FacebookBlockedError;
}(Error));
export { FacebookBlockedError };
