import drawBadgeWithText from './drawBadgeWithText'
import { scaleForFit } from '../common/canvasUtilities'

/**
 * @param canvas {HTMLCanvasElement}
 * @param baseImage {CanvasImageSource|null}
 * @param badgeImage {CanvasImageSource}
 * @param badgeDefinition
 * @param customTexts {Array<string>|null}
 */
export function drawImageWithBadge (canvas, baseImage, badgeImage, badgeDefinition, customTexts) {
  let context = canvas.getContext('2d')
  context.imageSmoothingEnabled = true
  context.imageSmoothingQuality = 'high'

  const canvasWidth = canvas.width
  const canvasHeight = canvas.height

  // Removing this line will cause an issue because the canvas context gets stuck
  canvas.width = canvasWidth

  context.clearRect(0, 0, canvasWidth, canvasHeight)

  if (baseImage != null) {
    context.drawImage(baseImage, 0, 0, canvasWidth, canvasHeight)
  }

  if (badgeImage != null) {
    context = scaleForFit(canvas, badgeImage, context, 'contain')
    drawBadgeWithText(context, badgeDefinition, badgeImage, customTexts)
  }
}

export function calculateScaledSize (size, longerSideLength) {
  // Calculate swapped if size is not in portrait
  if (size.width > size.height) {
    // noinspection JSSuspiciousNameCombination
    const swappedSize = {
      width: size.height,
      height: size.width
    }
    const calculatedSwappedSize = calculateScaledSize(swappedSize, longerSideLength)
    // noinspection JSSuspiciousNameCombination
    return {
      width: calculatedSwappedSize.height,
      height: calculatedSwappedSize.width
    }
  }

  // Now, size is always in portrait
  const scale = longerSideLength / size.height
  return {
    width: size.width * scale,
    height: size.height * scale
  }
}
