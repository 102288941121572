/**
 * @param {Element} element
 * @param {string} eventType
 * @param {function(Element, ElementEventMap): any} listener
 * @param {boolean | AddEventListenerOptions} options
 * @param {Element[]} additonalElements
 */
export function addEventListenerWhileDisabled (
  element,
  eventType,
  listener,
  options = false,
  ...additonalElements
) {
  element.addEventListener(eventType, async (sender, e) => {
    disable(element, ...additonalElements)
    try {
      let response = listener(sender, e)
      if (response instanceof Promise) {
        response = await response
      }
      return response
    } finally {
      enable(element, ...additonalElements)
    }
  }, options)
}

export async function executeDisabled (action, ...elements) {
  disable(...elements)
  try {
    let response = action()
    if (response instanceof Promise) {
      response = await response
    }
    return response
  } finally {
    enable(...elements)
  }
}

/**
 * @param {Element} elements
 */
export function disable (...elements) {
  setEnabled(false, ...elements)
}

/**
 * @param {Element} elements
 */
export function enable (...elements) {
  setEnabled(true, ...elements)
}

/**
 * @param {Boolean} enabledStatus
 * @param {Element} elements
 */
export function setEnabled (enabledStatus, ...elements) {
  const setEnabledStatus = enabledStatus
    ? element => { element.removeAttribute('disabled') }
    : element => { element.setAttribute('disabled', '') }
  elements.forEach(element => {
    setEnabledStatus(element)
  })
}

/**
 * @param {string} html
 * @returns {Element}
 */
export function renderHtml (html) {
  const parent = document.createElement('div')
  parent.innerHTML = html
  return parent.children[0]
}
