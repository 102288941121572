import { createPopper } from '@popperjs/core';
import './style.scss';
/**
 * @param element Element to attach the tooltip to.
 * @param message Content for the tooltip.
 * @param options Options for the tooltip. See https://popper.js.org/docs/v2/constructors/#options.
 * @returns The generated tooltip element.
 */
export default function showTooltip(element, message, options) {
    var tooltip = document.createElement('div');
    tooltip.classList.add('tooltip');
    var content = document.createElement('div');
    content.innerText = message;
    tooltip.appendChild(content);
    var arrow = document.createElement('div');
    arrow.classList.add('arrow');
    tooltip.appendChild(arrow);
    element.after(tooltip);
    createPopper(element, tooltip, options);
    return tooltip;
}
