import EventEmitter from 'event-emitter'

import { iOS } from '../../common/platformDetectUtilities.js'

export default class StreamSelector {
  /**
   * @param {HTMLVideoElement} videoElement
   * @param {MediaDeviceInfo[]} devices
   */
  constructor (videoElement, devices) {
    this._element = videoElement
    this._devices = devices
    this.events = new EventEmitter()
  }

  hasMultipleDevices() {
    return (
      iOS() // iOS lies with the available devices. Luckily, those always have two cameras anyway.
      || this._hasTwoDevicesWithDifferentFacings()
    )
  }

  async selectNextDevice () {
    const facingMode = this._toggleAndGetNextFacingMode()
    await this.selectFacingMode(facingMode)
  }

  async selectFacingMode (facingMode) {
    this.releaseStream()
    const stream = await this._getStreamFor(facingMode)
    await this._setStream(stream)
  }

  releaseStream () {
    this._element.pause()
    const stream = this._element.srcObject
    if (stream != null) {
      const tracks = stream.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
      this._element.srcObject = null
    }
  }

  async _setStream (stream) {
    this._element.srcObject = stream
    try {
      await this._element.play()
    } catch (error) {
      // Some browser do not allow this since technically, the video autoplays now
      console.warn('Could not trigger play')
    }
  }

  async _getStreamFor (facingMode) {
    return await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: facingMode
      }
    })
  }

  _toggleAndGetNextFacingMode() {
    if (this._isUser == null) {
      this._isUser = false
    }
    const oldFacingMode = getFacingModeFromToggle(this._isUser)
    this._isUser = !this._isUser
    const newFacingMode = getFacingModeFromToggle(this._isUser)
    this.events.emit('facingModeChanged', {
      old: oldFacingMode,
      new: newFacingMode
    })
    return newFacingMode
  }

  _hasTwoDevicesWithDifferentFacings () {
    let hasFrontFacing = false
    let hasBackFacing = false
    this._devices.forEach(device => {
      const label = device.label.toLowerCase()
      if (label.includes('front')) {
        hasFrontFacing = true
      } else if (label.includes('back')) {
        hasBackFacing = true
      }
    })
    return hasFrontFacing && hasBackFacing
  }
}

export async function hasAnyVideoDevice () {
  const videoDevices = await getVideoDevices()
  return videoDevices.length > 0
}

export async function getVideoDevices () {
  const devices = await enumerateDevices()
  return devices.filter(device => {
    return device.kind === 'videoinput'
  })
}

/**
 * @returns {boolean}
 */
export async function canSelectStreams () {
  try {
    await getVideoDevices();
  } catch (error) {
    return false;
  }
  if (!'mediaDevices' in navigator) {
    return false;
  }
  if (!'getUserMedia' in navigator.mediaDevices) {
    return false;
  }
  return true;
}

async function enumerateDevices () {
  if (!document.hasOwnProperty('streamSelectorDeviceEnumerationPromise')) {
    document.streamSelectorDeviceEnumerationPromise = navigator.mediaDevices.enumerateDevices()
  }
  return await document.streamSelectorDeviceEnumerationPromise
}

function getFacingModeFromToggle(isUser) {
  if (isUser == null)
    return null
  return isUser ? 'user' : 'environment'
}
