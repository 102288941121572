import EventEmitter from 'event-emitter'

const KEY = 'sharepic-event-emitter'

export default function getInstance () {
  if (!Object.prototype.hasOwnProperty.call(document, KEY)) {
    document[KEY] = new EventEmitter()
  }
  return document[KEY]
}
