var config = {
    version: 1,
    hideDeclineAll: true,
    acceptAll: true,
    translations: {
        de: {
            privacyPolicyUrl: '/privacy',
            googleAnalytics: {
                title: 'Google Analytics'
            },
            purposes: {
                analytics: 'Analyse'
            }
        }
    },
    services: [
        {
            name: 'googleAnalytics',
            purposes: ['analytics'],
            cookies: [
                /_ga(_.+)?/,
            ]
        },
    ],
};
export default config;
