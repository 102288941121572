import Textbox from '@borgar/textbox'
import { hasCustomText } from './customText'

export default function drawBadgeWithText (context, badgeDefinition, image, texts) {
  context.drawImage(image, 0, 0, image.width, image.height)
  if (!hasCustomText(badgeDefinition)) {
    return
  }
  drawTextsOnto(context, badgeDefinition.customTexts, texts)
}

function drawTextsOnto (context, specs, texts) {
  specs.forEach((spec, index) => {
    let text

    if (texts != null && texts.length > index) {
      text = texts[index]
    }

    if (text == null || text.trim() === '') {
      text = spec.placeholder
    }

    drawTextOnto(context, spec, text)
  })
}

function drawTextOnto (context, specs, text) {
  const bounds = specs.bounds
  context.translate(bounds.x, bounds.y)
  const box = getFittingBoxFor(specs, text)
  const lines = box.linebreak(text)
  const rot = new Textbox.Rotator({
    width: specs.bounds.width,
    height: specs.bounds.height,
    rotate: specs.bounds.rotation,
    anchor: 'top left'
  })
  rot.render(context => {
    box.render(context, text)
  }, context)
}

function getFittingBoxFor (specs, text) {
  for (let fontSize = specs.font.size; fontSize > 0; fontSize--) {
    const box = getBoxAtSize(specs, text, fontSize)
    if (doesFit(specs, box, text)) {
      return box
    }
  }

  console.warn('No fitting font size found, using default size')
  return getBoxAtSize(specs, text, specs.font.size)
}

function getBoxAtSize (specs, text, fontSize) {
  const font = JSON.parse(JSON.stringify(specs.font))
  font.size = fontSize
  const box = new Textbox({
    width: specs.bounds.width,
    height: specs.bounds.height,
    align: specs.align.h,
    valign: specs.align.v,
    font: font
  })
  return box
}

function doesFit (specs, box, text) {
  const previousHeight = box.height()
  box.height(Infinity)

  const lines = box.linebreak(text)

  box.height(previousHeight)

  return lines.height <= specs.bounds.height
}

function calculateScaledSize (size, longerSideLength) {
  // Calculate swapped if size is not in portrait
  if (size.width > size.height) {
    const swappedSize = {
      width: size.height,
      height: size.width
    }
    const calculatedSwappedSize = calculateScaledSize(swappedSize, longerSideLength)
    return {
      width: calculatedSwappedSize.height,
      height: calculatedSwappedSize.width
    }
  }

  // Now, size is always in portrait
  const scale = longerSideLength / size.height
  return {
    width: size.width * scale,
    height: size.height * scale
  }
}
