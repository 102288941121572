import './style.scss';
import ee from 'event-emitter';
var Radio = /** @class */ (function () {
    function Radio(group) {
        var _this = this;
        this.group = group;
        this.options = group.querySelectorAll('*[x-value]');
        this.events = ee();
        this.options.forEach(function (option) {
            _this.registerOption(option);
        });
    }
    Object.defineProperty(Radio.prototype, "selected", {
        get: function () {
            var _this = this;
            var result = null;
            this.options.forEach(function (option) {
                if (!result) {
                    if (_this.isActive(option)) {
                        result = option;
                    }
                }
            });
            return result;
        },
        set: function (value) {
            this.unactivateAll();
            if (value != null) {
                this.setActive(value, true);
            }
            this.onOptionSelected(value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Radio.prototype, "value", {
        get: function () {
            var option = this.selected;
            if (option == null) {
                return null;
            }
            else {
                return Radio.getValueOf(option);
            }
        },
        enumerable: false,
        configurable: true
    });
    Radio.prototype.isActive = function (option) {
        return option.classList.contains('active');
    };
    Radio.prototype.registerOption = function (option) {
        var _this = this;
        option.addEventListener('click', function () {
            _this.handleOnClick(option);
        });
    };
    Radio.prototype.handleOnClick = function (option) {
        if (this.isActive(option)) {
            this.setActive(option, false);
            this.onOptionSelected(null);
        }
        else {
            this.unactivateAll();
            this.setActive(option, true);
            this.onOptionSelected(option);
        }
    };
    Radio.prototype.onOptionSelected = function (option) {
        var value = Radio.getValueOf(option);
        this.events.emit('valueChanged', value, option);
    };
    Radio.prototype.setActive = function (option, isActive) {
        var options = this.getAllIdenticalOptions(option);
        if (isActive) {
            options.forEach(function (option) { return option.classList.add('active'); });
        }
        else {
            options.forEach(function (option) { return option.classList.remove('active'); });
        }
    };
    Radio.prototype.getAllIdenticalOptions = function (option) {
        var value = Radio.getValueOf(option);
        return this.group.querySelectorAll("*[x-value=\"" + value + "\"]");
    };
    Radio.prototype.unactivateAll = function () {
        var _this = this;
        this.options.forEach(function (option) {
            _this.setActive(option, false);
        });
    };
    Radio.getValueOf = function (option) {
        return (option === null || option === void 0 ? void 0 : option.getAttribute('x-value')) || null;
    };
    return Radio;
}());
export default Radio;
