import modalString from './modal.html'
import './index.scss'
import './StreamSelector.mjs'
import StreamSelector, { canSelectStreams, getVideoDevices, hasAnyVideoDevice } from './StreamSelector.mjs'
import { scaleForFit } from '../../common/canvasUtilities.js'
import { addEventListenerWhileDisabled, executeDisabled } from '../../common/htmlElementUtilities.js'

export default class CameraModal {
  constructor () {
    this._modal = renderHtml(modalString)
    this._modalInDom = false

    this._videoStreamElement = this._modal.querySelector('.video-stream')
    this._badgeOverlayContainer = this._modal.querySelector('.badge-overlay-container')
    this._cancelButton = this._modal.querySelector('.cancel-button')
    this._switchCameraButton = this._modal.querySelector('.switch-camera-button')
    this._takePhotoButton = this._modal.querySelector('.take-photo-button')
    this._videoMode = this._modal.querySelector('.video-mode')
    this._photoMode = this._modal.querySelector('.photo-mode')
    this._retakeButton = this._modal.querySelector('.retake-button')
    this._confirmButton = this._modal.querySelector('.confirm-button')

    this._resetModal()
    this._setupListeners()
  }

  async pickFromCamera (badgeImage, size) {
    return new Promise((resolve, reject) => {
      try {
        this._promiseResolve = resolve
        this._promiseReject = reject
        this._setBadgeImage(badgeImage)
        this._targetSize = size
        this._openModal().catch(error => {
          this._reject(error)
        })
      } catch (error) {
        this._reject(error)
      }
    })
  }

  async _openModal () {
    await executeDisabled(async () => {
      this._showModal()
      await this._setupStream()
    }, this._switchCameraButton, this._takePhotoButton, this._cancelButton)
  }

  async _setupStream () {
    const devices = await getVideoDevices()
    this._streamSelector = new StreamSelector(this._videoStreamElement, devices)
    if (this._streamSelector.hasMultipleDevices()) {
      this._switchCameraButton.classList.add('show')
    } else {
      this._switchCameraButton.classList.remove('show')
    }
    this._streamSelector.events.on('facingModeChanged', ({ new: newMode }) => {
      if (newMode === 'user') {
        this._videoStreamElement.style.transform = 'scale(-1, 1)'
        this._isMirrored = true
      } else {
        this._videoStreamElement.style.transform = null
        this._isMirrored = false
      }
    })
    await this._streamSelector.selectNextDevice()
  }

  cancel () {
    this._resolve(null)
    this._hideModal()
  }

  _showModal () {
    this._addToDomIfRequired()
    this._modal.classList.add('show')
    this._modal.focus()
  }

  _hideModal () {
    this._modal.classList.remove('show')
  }

  _setBadgeImage (badgeImage) {
    this._badgeOverlayContainer.appendChild(badgeImage)
    this._badgeImage = badgeImage
  }

  _setupListeners () {
    this._modal.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.cancel()
      }
    })
    this._cancelButton.addEventListener('click', () => {
      this.cancel()
    })
    addEventListenerWhileDisabled(this._switchCameraButton, 'click', async () => {
      await this._streamSelector.selectNextDevice()
    }, false, this._takePhotoButton)
    this._takePhotoButton.addEventListener('click', () => {
      this._videoStreamElement.pause()
      this._videoMode.classList.remove('show')
      this._photoMode.classList.add('show')
    })
    this._retakeButton.addEventListener('click', () => {
      this._videoStreamElement.play()
      this._videoMode.classList.add('show')
      this._photoMode.classList.remove('show')
    })
    addEventListenerWhileDisabled(this._confirmButton, 'click', async () => {
      const canvas = await getCanvasFromVideo(this._videoStreamElement, this._targetSize, this._isMirrored)
      this._resolve(canvas)
    }, false, this._retakeButton, this._cancelButton)
  }

  _addToDomIfRequired () {
    if (!this._modalInDom) {
      document.body.appendChild(this._modal)
      this._modalInDom = true
    }
  }

  _resolve (result) {
    if (this._promiseResolve == null)
      return
    this._promiseResolve(result)
    this._resetModal()
  }

  _reject (error) {
    if (this._promiseReject == null)
      return
    this._promiseReject(error)
    this._resetModal()
  }

  _resetModal () {
    this._promiseResolve = null
    this._promiseReject = null
    this._hideModal()
    this._streamSelector?.releaseStream()
    this._videoMode.classList.add('show')
    this._photoMode.classList.remove('show')
  }
}

const imageCanvas = document.createElement('canvas')

/**
 * @returns {boolean}
 */
export async function canPickWithCamera () {
  return await canSelectStreams() && await hasAnyVideoDevice()
}

/**
 * @param {HTMLVideoElement} video
 * @param {{width: Number, height: Number}} size
 * @param {boolean} mirrorVideo
 * @returns {Blob}
 */
async function getCanvasFromVideo (video, size, mirrorVideo) {
  return new Promise((resolve, reject) => {
    try {
      imageCanvas.width = size.width
      imageCanvas.height = size.height
      let context = imageCanvas.getContext('2d')
      const videoBounds = {
        width: video.videoWidth,
        height: video.videoHeight
      }
      context = scaleForFit(size, videoBounds, context, 'cover')
      if (mirrorVideo) {
        context.translate(videoBounds.width, 0)
        context.scale(-1, 1)
      }
      context.drawImage(video, 0, 0, videoBounds.width, videoBounds.height)
      resolve(imageCanvas)
    } catch (error) {
      reject(error)
    }
  })
}

function renderHtml (string) {
  const parent = document.createElement('div')
  parent.innerHTML = string
  return parent.children[0]
}
