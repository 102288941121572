import loadImage from '../common/loadImage'

export default class BadgeImageHolder {
  constructor (badgeDefinitions) {
    this._badgePromises = []
    this._badgesWithSizeVariants = []
    this._loadBadges(badgeDefinitions)
  }

  setSelectedSize (selectedSize) {
    this._badgesWithSizeVariants.forEach(badge => {
      const path = getPath(badge, selectedSize)
      const key = getKey(badge)
      this._badgePromises[key] = loadImage(path)
    })
  }

  async getBadgeImageFor (badgeDefinition) {
    const key = getKey(badgeDefinition)
    return await this._badgePromises[key]
  }

  _loadBadges (badgeDefinitions) {
    badgeDefinitions.forEach(badge => {
      const path = getPath(badge, null)
      if (path == null) {
        this._badgesWithSizeVariants.push(badge)
        return
      }
      const key = getKey(badge)
      this._badgePromises[key] = loadImage(path)
    })
  }
}

function getKey (badgeDefinition) {
  return badgeDefinition.slug
}

function getPath (badge, selectedSize) {
  const slug = getSlugOrNull(badge, selectedSize)
  if (slug == null) {
    return null
  } else {
    return `${slug}.png`
  }
}

function getSlug (badge, selectedSize) {
  const variants = badge.sizeVariants
  const suitableVariant = variants.find(variant => {
    return variant.sizeName === selectedSize.name
  })
  if (suitableVariant == null) {
    return badge.slug
  } else {
    return suitableVariant.slug
  }
}

function getSlugOrNull (badge, selectedSize) {
  if (hasSizeVariants(badge)) {
    if (!selectedSize) {
      return null
    } else {
      return getSlug(badge, selectedSize)
    }
  } else {
    return badge.slug
  }
}

function hasSizeVariants (badgeDefinition) {
  if (!badgeDefinition.sizeVariants) {
    return false
  }
  const sizeVariants = badgeDefinition.sizeVariants
  if (!(sizeVariants instanceof Array)) {
    return false
  }
  if (sizeVariants.length <= 0) {
    return false
  }
  return true
}
