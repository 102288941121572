import EventEmitter from 'event-emitter'
import { renderHtml } from './htmlElementUtilities'

export default class Modal {
  /**
   * @param {String|Element} modal
   */
  constructor (modal) {
    if (typeof modal === 'string')
      this._modal = renderHtml(modal)
    else if (modal instanceof Element)
      this._modal = modal
    else {
      console.error('Invalid type for modal', modal)
    }
    this.events = new EventEmitter()
  }

  /**
   * @param {any} options
   * @returns {Promise<any>}
   */
  async prompt (options = {}) {
    return new Promise((resolve, reject) => {
      try {
        this._promiseResolve = resolve
        this._promiseReject = reject
        this._showModal()
        this.onPrompt(options).catch(error => {
          this._reject(error)
        })
      } catch (error) {
        this._reject(error)
      }
    })
  }

  /**
   * @param {any} result
   */
  finishWithResult (result) {
    this._resolve(result)
  }

  finishWithCancel () {
    this._resolve(null)
  }

  _showModal () {
    this._addToDomIfRequired()
    this._modal.classList.add('show')
    this._modal.focus()
  }

  _hideModal () {
    this._modal.classList.remove('show')
  }

  _addToDomIfRequired () {
    if (!this._modalInDom) {
      document.body.appendChild(this._modal)
      this._modalInDom = true
    }
  }

  _resolve (result) {
    if (this._promiseResolve == null)
      return
    this._promiseResolve(result)
    this._resetPromisesAndHide()
  }

  _reject (error) {
    if (this._promiseReject == null)
      return
    this._promiseReject(error)
    this._resetPromisesAndHide()
  }

  _resetPromisesAndHide () {
    this._promiseResolve = null
    this._promiseReject = null
    this._hideModal()
  }

  /**
   * Executed right before the modal is shown.
   * @param {any} options Options that were passed to prompt.
   * @returns {Promise<void>}
   */
  async onPrompt(options) {

  }
}
