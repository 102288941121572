export default async function loadImage (path) {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image()
      image.onload = () => {
        resolve(image)
      }
      image.src = path

    } catch (error) {
      reject(image)
    }
  })
}
