var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import showTooltip from '../tooltip';
/**
 * Promote this element by highlighting it with an animation
 *
 * @param element Element to promote.
 * @param message Text to show while promoting this element.
 * @param options Options for the tooltip. See https://popper.js.org/docs/v2/constructors/#options.
 * @param duration Time in seconds to promote this element.
 * @returns Completes once the whole promotion animation is done
 */
export function growAndShrinkWithText(element, message, options, duration) {
    if (duration === void 0) { duration = .75; }
    return __awaiter(this, void 0, void 0, function () {
        var tooltipDone;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tooltipDone = flashTooltip(element, message, options);
                    return [4 /*yield*/, growAndShrink(element, duration)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, tooltipDone];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
/**
 * Show a tooltip for as long as it takes to read it.
 * The tooltip is removed from the DOM afterward.
 *
 * @param element Element to attach the tooltip to.
 * @param message Content of the tooltip.
 * @param options Options for the tooltip. See https://popper.js.org/docs/v2/constructors/#options.
 */
function flashTooltip(element, message, options) {
    return __awaiter(this, void 0, void 0, function () {
        var duration, fadeOutDuration, tooltip;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    duration = calculateReadingTimeFor(message) + 1;
                    fadeOutDuration = 0.2;
                    tooltip = showTooltip(element, message, options);
                    return [4 /*yield*/, sleep(duration - fadeOutDuration)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, fadeOutAndRemove(tooltip, fadeOutDuration)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
/**
 * @param element
 * @param duration Duration for the animation in seconds.
 */
function growAndShrink(element, duration) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    element.animate([
                        { transform: "scale(1)", boxShadow: 'black 0 0 0' },
                        { transform: "scale(1.1)", boxShadow: 'black 0 0 10px' },
                        { transform: "scale(1)", boxShadow: 'black 0 0 0' }
                    ], {
                        duration: duration * 1000,
                        easing: 'cubic-bezier(0.4, 0, 1, 1)'
                    });
                    return [4 /*yield*/, sleep(duration)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
/**
 * @param element
 * @param duration Duration for the animation in seconds.
 * @returns Completes once the element is removed from the DOM.
 */
function fadeOutAndRemove(element, duration) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    element.animate([
                        { opacity: 0 }
                    ], {
                        duration: duration * 1000,
                        easing: 'ease-in',
                        fill: 'forwards'
                    });
                    return [4 /*yield*/, sleep(duration)];
                case 1:
                    _a.sent();
                    element.remove();
                    return [2 /*return*/];
            }
        });
    });
}
/**
 * @param duration Sleep duration in seconds.
 */
function sleep(duration) {
    var durationMs = duration * 1000;
    return new Promise(function (resolve) {
        setTimeout(resolve, durationMs);
    });
}
/**
 * @returns {number} Reading time in seconds.
 */
function calculateReadingTimeFor(text) {
    var wpm = 160;
    var words = text.split(' ').length;
    var minutes = words / wpm;
    var seconds = minutes * 60;
    return seconds;
}
