export function hasCustomText (badgeDefinition) {
  if (!Object.prototype.hasOwnProperty.call(badgeDefinition, 'customTexts')) {
    return false
  }
  const customTexts = badgeDefinition.customTexts
  return customTexts.length > 0
}

export function promptForCustomTexts (badgeDefinition) {
  const customTexts = badgeDefinition.customTexts
  return customTexts.map(customText => {
    const label = customText.label
    const placeholder = customText.placeholder
    return prompt(label, placeholder)
  })
}
