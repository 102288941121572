var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function getSharingDetailsWithCurrentPage(badge, customTexts) {
    var currentPage = document.location.href;
    return getSharingDetailsWithUrl(currentPage, badge, customTexts);
}
export function getSharingDetails(badge, customTexts) {
    var currentPage = document.location.href;
    var defaultValues = {
        url: currentPage,
        title: badge.name,
        hashtags: []
    };
    var configured = badge.share;
    return __assign(__assign({}, defaultValues), configured);
}
export function getSharingDetailsWithUrl(url, badge, customTexts) {
    var sharingDetailsWithoutUrl = getSharingDetails(badge, customTexts);
    if (!url)
        return sharingDetailsWithoutUrl;
    else {
        return __assign(__assign({}, sharingDetailsWithoutUrl), { url: url });
    }
}
