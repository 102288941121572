var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ImageUploader = /** @class */ (function () {
    function ImageUploader(canvas, progressBar) {
        var _this = this;
        this.state = 'not-uploaded';
        this.canvas = canvas;
        this.progressBar = progressBar;
        window.onbeforeunload = function (_) { return _this.beforeUnloadHandler(); };
    }
    ImageUploader.prototype.uploadOrGet = function (badgeSlug, userId) {
        return __awaiter(this, void 0, void 0, function () {
            var response, message_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.state === 'not-uploaded')) return [3 /*break*/, 6];
                        this.state = 'uploading';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.showUploadProgress();
                        return [4 /*yield*/, this.uploadAndGetResponse(this.canvas, badgeSlug, userId, function (progress) {
                                _this.progressBar.value = progress;
                            })];
                    case 2:
                        response = _a.sent();
                        this.url = response.url;
                        this.confirmationToken = response.confirmationToken;
                        this.state = 'uploaded';
                        return [2 /*return*/, this.url];
                    case 3:
                        message_1 = _a.sent();
                        this.state = 'not-uploaded';
                        throw message_1;
                    case 4:
                        this.hideUploadProgress();
                        return [7 /*endfinally*/];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        if (this.state === 'uploading') {
                            return [2 /*return*/, null];
                        }
                        else if (this.state === 'uploaded' || this.state === 'confirmed') {
                            return [2 /*return*/, this.url];
                        }
                        else {
                            console.error("Unknown state: " + this.state);
                            return [2 /*return*/, null];
                        }
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ImageUploader.prototype.confirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.state !== 'uploaded') {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tryConfirm()];
                    case 2:
                        _a.sent();
                        this.state = 'confirmed';
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Could not confirm upload', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ImageUploader.prototype.invalidate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.state !== 'uploaded') {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        // If the request fails, the state still has to be updated
                        // because a dangling image gets cleaned up anyways
                        // and an invalid state will cause to share the old image
                        this.state = 'not-uploaded';
                        return [4 /*yield*/, this.tryInvalidate()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.error('Could not invalidate', error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ImageUploader.prototype.tryConfirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formData, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        if (this.confirmationToken == null)
                            throw 'Missing confirmation token';
                        formData.set('token', this.confirmationToken);
                        return [4 /*yield*/, fetch('/upload/confirm', {
                                method: 'post',
                                body: formData
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) {
                            throw response.status + ": " + response.statusText;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ImageUploader.prototype.tryInvalidate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formData, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        if (this.confirmationToken == null)
                            throw 'Missing confirmation token';
                        formData.set('token', this.confirmationToken);
                        return [4 /*yield*/, fetch('/upload/invalidate', {
                                method: 'post',
                                body: formData
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) {
                            throw response.status + ": " + response.statusText;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ImageUploader.prototype.uploadAndGetResponse = function (canvas, badgeSlug, userId, onUploadProgress) {
        return __awaiter(this, void 0, void 0, function () {
            var imageBlob, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getBlobFromCanvasWithSuitableSize(canvas)];
                    case 1:
                        imageBlob = _a.sent();
                        url = this.getUploadUrl(badgeSlug, userId);
                        return [2 /*return*/, this.uploadBlob(url, 'image', imageBlob, onUploadProgress)];
                }
            });
        });
    };
    ImageUploader.prototype.uploadBlob = function (path, fieldName, blob, onUploadProgress) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var formData = new FormData();
                        formData.append(fieldName, blob);
                        var request = new XMLHttpRequest();
                        request.open('post', path, true);
                        request.onerror = function () {
                            reject('Request failed');
                        };
                        request.upload.onprogress = function (event) {
                            var progress = event.loaded / event.total;
                            onUploadProgress(progress);
                        };
                        request.onload = function () {
                            if (request.status >= 400) {
                                reject(request.status + ": " + request.statusText);
                            }
                            else {
                                var response = request.response;
                                var parsed = JSON.parse(response);
                                resolve(parsed);
                            }
                        };
                        request.send(formData);
                    })];
            });
        });
    };
    ImageUploader.prototype.getUploadUrl = function (badgeSlug, userId) {
        return "upload?badge=" + badgeSlug + "&user_id=" + userId;
    };
    ImageUploader.prototype.showUploadProgress = function () {
        this.progressBar.value = 0;
        this.progressBar.classList.add('show');
    };
    ImageUploader.prototype.hideUploadProgress = function () {
        this.progressBar.classList.remove('show');
    };
    ImageUploader.prototype.beforeUnloadHandler = function () {
        if (this.state === 'uploading') {
            return 'Wenn du jetzt das Fenster schließt, wird das Bild nicht geteilt!';
        }
        else {
            return null;
        }
    };
    return ImageUploader;
}());
export default ImageUploader;
function getBlobFromCanvasWithSuitableSize(canvas) {
    return __awaiter(this, void 0, void 0, function () {
        var config, sizeLimit, blob, quality, iterationCountdown;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    config = CONFIG.tabs.download.shareUpload;
                    sizeLimit = config.sizeLimitMb * 1024 * 1024;
                    quality = 0.9;
                    iterationCountdown = 50;
                    _a.label = 1;
                case 1: return [4 /*yield*/, getBlobFromCanvas(canvas, config.type, quality)];
                case 2:
                    blob = _a.sent();
                    quality *= 0.8;
                    iterationCountdown--;
                    if (iterationCountdown <= 0) {
                        return [2 /*return*/, blob];
                    }
                    _a.label = 3;
                case 3:
                    if (blob.size > sizeLimit) return [3 /*break*/, 1];
                    _a.label = 4;
                case 4: return [2 /*return*/, blob];
            }
        });
    });
}
function getBlobFromCanvas(canvas, type, quality) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    canvas.toBlob(function (blob) {
                        resolve(blob);
                    }, type, quality);
                })];
        });
    });
}
