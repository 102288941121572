var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import './style.scss';
import { sendEvent } from '../analytics';
var wizard = document.getElementById('wizard');
var tabs = document.getElementsByClassName('tab');
var tabCount = tabs.length;
var buttonPrevious = document.getElementById('wizard-previous');
var buttonNext = document.getElementById('wizard-next');
var nextButtonFirstText = buttonNext.getAttribute('x-first-text');
var buttonContainer = document.getElementById('btn-container');
var buttonContainerIntro = document.getElementById('btn-container-intro');
var onNextListeners = [];
var onPreviousListeners = [];
var onLoadListeners = [];
var WizardEvent = /** @class */ (function () {
    function WizardEvent(buttonNext, buttonPrevious) {
        this.cancel = false;
        this.context = {
            buttonNext: buttonNext,
            buttonPrevious: buttonPrevious
        };
    }
    return WizardEvent;
}());
export { WizardEvent };
var tabIndex = 0;
var lastStateName = null;
goTo(tabIndex).catch(function (error) {
    console.error('Error while loading first page', error);
});
export function goTo(n) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tabs[tabIndex].classList.remove('active');
                    tabIndex = n;
                    tabs[tabIndex].classList.add('active');
                    if (n === 0) {
                        buttonContainerIntro.style.display = 'inline';
                        buttonPrevious.style.display = 'none';
                        buttonContainer.classList.remove('btn-position');
                        buttonContainerIntro.appendChild(buttonNext);
                        buttonContainer.style.display = 'none';
                        if (nextButtonFirstText != null)
                            buttonNext.innerText = nextButtonFirstText;
                    }
                    else {
                        buttonContainer.style.display = 'inline';
                        buttonContainerIntro.style.display = 'none';
                        buttonContainer.appendChild(buttonNext);
                        buttonPrevious.style.display = 'inline';
                        buttonContainer.classList.add('btn-position');
                        buttonNext.innerText = 'Weiter';
                    }
                    if (n >= tabCount - 1) {
                        buttonNext.style.visibility = 'hidden';
                    }
                    else {
                        buttonNext.style.visibility = 'visible';
                    }
                    updateWizardClass();
                    return [4 /*yield*/, processListeners(onLoadListeners)];
                case 1:
                    _a.sent();
                    sendEvent('screen_view', {
                        screen_name: getCurrentState()
                    });
                    return [2 /*return*/];
            }
        });
    });
}
export function next() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, goTo(tabIndex + 1)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function previous() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, goTo(tabIndex - 1)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function currentIndex() {
    return tabIndex;
}
export function onNext(state, callback) {
    onNextListeners.push({
        state: state,
        callback: callback
    });
}
export function onPrevious(state, callback) {
    onPreviousListeners.push({
        state: state,
        callback: callback
    });
}
export function onLoad(state, callback) {
    onLoadListeners.push({
        state: state,
        callback: callback
    });
}
export function getCurrentState() {
    var currentTab = tabs[tabIndex];
    var state = currentTab.getAttribute('x-name');
    if (state == null || state === '') {
        throw Error('Empty state');
    }
    return state;
}
export function disableNext() {
    setNextEnabled(false);
}
export function enableNext() {
    setNextEnabled(true);
}
buttonPrevious.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () {
    var event;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, processListeners(onPreviousListeners)];
            case 1:
                event = _a.sent();
                if (!event.cancel) {
                    previous();
                }
                return [2 /*return*/];
        }
    });
}); });
buttonNext.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () {
    var event;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, processListeners(onNextListeners)];
            case 1:
                event = _a.sent();
                if (!event.cancel) {
                    next();
                }
                return [2 /*return*/];
        }
    });
}); });
function updateWizardClass() {
    if (lastStateName != null) {
        wizard.classList.remove(lastStateName);
    }
    lastStateName = null;
    var state = getCurrentState();
    var className = "wizard-state-" + state;
    if (state != null) {
        wizard.classList.add(className);
        lastStateName = className;
    }
}
function processListeners(listenerCollection) {
    return __awaiter(this, void 0, void 0, function () {
        var state, event, applicableListeners, _a, _b, _i, index, listener, callbackResponse;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getCurrentState();
                    event = new WizardEvent(buttonNext, buttonPrevious);
                    applicableListeners = listenerCollection.filter(function (listener) { return listener.state === state; });
                    _a = [];
                    for (_b in applicableListeners)
                        _a.push(_b);
                    _i = 0;
                    _c.label = 1;
                case 1:
                    if (!(_i < _a.length)) return [3 /*break*/, 5];
                    index = _a[_i];
                    if (!applicableListeners.hasOwnProperty(index)) {
                        return [3 /*break*/, 4];
                    }
                    listener = applicableListeners[index];
                    callbackResponse = listener.callback(event);
                    if (!(callbackResponse instanceof Promise)) return [3 /*break*/, 3];
                    return [4 /*yield*/, callbackResponse];
                case 2:
                    _c.sent();
                    _c.label = 3;
                case 3:
                    if (event.cancel) {
                        return [3 /*break*/, 5];
                    }
                    _c.label = 4;
                case 4:
                    _i++;
                    return [3 /*break*/, 1];
                case 5: return [2 /*return*/, event];
            }
        });
    });
}
export function setNextEnabled(isEnabled) {
    buttonNext.disabled = !isEnabled;
}
