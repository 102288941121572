const templateInfoPopup = getInfoPopupTemplate()

/**
 * @param {HTMLCanvasElement} badge
 */
export default function addInfoPopupForCustomTextTo (badge) {
  const wrapper = badge.parentElement
  const infoPopup = getInfoPopup()
  wrapper.prepend(infoPopup)
}

function getInfoPopup () {
  const cloned = templateInfoPopup.cloneNode(true)
  return cloned
}

/**
 * @returns {HTMLElement}
 */
function getInfoPopupTemplate () {
  const template = document.querySelector('.custom-text-info-wrapper')
  template.remove()
  template.classList.remove('hidden')
  return template
}
