/**
 * Stolen from https://stackoverflow.com/questions/9038625/detect-if-device-is-ios#9039885
 * @returns {boolean} true if device runs iOS
 */
export function iOS () {
    try {
      return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    } catch (error) {
      return false
    }
}
