import './style.scss';
var ProgressBar = /** @class */ (function () {
    function ProgressBar(element) {
        this.classList = element.classList;
        element.classList.add('progress-bar');
        this.inner = document.createElement('div');
        this.inner.classList.add('progress-bar-inner');
        element.appendChild(this.inner);
        this._value = 0;
    }
    Object.defineProperty(ProgressBar.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (newValue) {
            var percent = newValue * 100;
            this.inner.style.width = percent + "%";
            this._value = newValue;
        },
        enumerable: false,
        configurable: true
    });
    return ProgressBar;
}());
export default ProgressBar;
