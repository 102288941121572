import Modal from '../../../../common/Modal'

import html from './modal.html'
import './index.scss'

export default class PrivacyModal extends Modal {
  constructor () {
    super(html)
    this._yesButton = this._modal.querySelector('.yes-button')
    this._noButton = this._modal.querySelector('.no-button')

    this._yesButton.addEventListener('click', () => {
      this.finishWithResult(true)
    })
    this._noButton.addEventListener('click', () => {
      this.finishWithResult(false)
    })
  }
}
