var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as download from 'downloadjs';
import './config-type';
import * as Wizard from './wizard';
import Radio from './radio';
import ProgressBar from './progress-bar';
import NativeShareService from './profile/sharing/NativeShareService';
import { FacebookBlockedError } from './profile/sharing/facebook/FacebookService';
import FacebookShareService from './profile/sharing/FacebookShareService';
import { getSharingDetails } from './profile/sharing/sharingDetails';
import { hasCustomText } from './profile/customText.js';
import BadgeImageHolder from './profile/BadgeImageHolder';
import getGlobalEmitterInstance from './common/GlobalEventEmitter';
import addInfoPopupForCustomTextTo from './profile/customTextInfoPopups';
import { calculateScaledSize, drawImageWithBadge } from './profile/imageAndBadgeDrawing';
import { debounce } from './common/eventUtilities';
import CameraModal, { canPickWithCamera } from './profile/camera-modal';
import './analytics';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import 'normalize.css';
import './styles/profile.scss';
import { addEventListenerWhileDisabled } from './common/htmlElementUtilities';
import loadImage from './common/loadImage';
import { setup as setupPrivacy } from './privacy';
import { sendEvent, context } from './analytics';
import { iOS } from './common/platformDetectUtilities';
import { growAndShrinkWithText } from './animation';
context.profile = profile.slug;
var eventEmitter = getGlobalEmitterInstance();
var badgeImageHolder = new BadgeImageHolder(profile.badges);
setupPrivacy();
// Tab intro
//
Wizard.onLoad('intro', function () {
    Wizard.enableNext();
});
var sizeSelectionGroup = new Radio(document.getElementById('size-selection-group'));
var customSizeInputs = document.getElementById('custom-size-inputs');
var selectedSize = null;
forceSelectionForContinue('size-selection', sizeSelectionGroup);
sizeSelectionGroup.events.on('valueChanged', function (newValue) {
    if (newValue === 'custom') {
        customSizeInputs.style.display = 'flex';
    }
    else {
        customSizeInputs.style.display = 'none';
    }
});
Wizard.onNext('size-selection', function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        badgeImageHolder.setSelectedSize(selectedSize);
        sendEvent('select_size', {
            id: selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.name,
            dimensions: (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.width) + "x" + (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.height)
        });
        return [2 /*return*/];
    });
}); });
Wizard.onNext('size-selection', function (e) {
    var selected = sizeSelectionGroup.selected;
    if (selected == null) {
        console.error('Nothing selected but next is pressed');
        e.cancel = true;
        return;
    }
    selectedSize = getSizeValuesOf(selected);
});
function getSizeValuesOf(element) {
    if (element.id === 'custom-size-selection') {
        return getCustomSizeValues();
    }
    else {
        return {
            width: element.getAttribute('x-width'),
            height: element.getAttribute('x-height'),
            name: element.getAttribute('x-value')
        };
    }
}
function getCustomSizeValues() {
    var widthInput = document.getElementById('x-pixel');
    var heightInput = document.getElementById('y-pixel');
    return {
        width: widthInput.value,
        height: heightInput.value,
        name: 'custom'
    };
}
// Tab badge-selection
//
var badgeSelectionConfig = CONFIG.tabs.badgeSelection;
var badgeSelectionGroupElement = document.getElementById('badge-selection-group');
var badgeSelectionGroup = new Radio(badgeSelectionGroupElement);
var selectedBadge = null;
var selectedBadgeImage = null;
var customTexts = [];
forceSelectionForContinue('badge-selection', badgeSelectionGroup);
Wizard.onLoad('badge-selection', function () { return __awaiter(void 0, void 0, void 0, function () {
    var firstBadge;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, buildPreviews()];
            case 1:
                _a.sent();
                firstBadge = badgeSelectionGroupElement.children[0];
                if (firstBadge && !badgeSelectionGroup.selected)
                    badgeSelectionGroup.selected = firstBadge;
                return [2 /*return*/];
        }
    });
}); });
Wizard.onNext('badge-selection', function () { return __awaiter(void 0, void 0, void 0, function () {
    var selectedBadgeSlug, selectedBadgeImageFuture;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                selectedBadgeSlug = badgeSelectionGroup.value;
                selectedBadge = profile.badges.find(function (badge) { return badge.slug === selectedBadgeSlug; });
                selectedBadgeImageFuture = badgeImageHolder.getBadgeImageFor(selectedBadge);
                eventEmitter.emit('badge-selected', {
                    definition: selectedBadge,
                    imageFuture: selectedBadgeImageFuture
                });
                sendEvent('select_badge', {
                    id: selectedBadge.slug
                });
                return [4 /*yield*/, selectedBadgeImageFuture];
            case 1:
                selectedBadgeImage = _a.sent();
                return [2 /*return*/];
        }
    });
}); });
function buildPreviews() {
    return __awaiter(this, void 0, void 0, function () {
        var placeholderImageLoaded, canvasSize, badgesLoadedPromises;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    placeholderImageLoaded = loadImage('/img/placeholder.jpg');
                    canvasSize = calculateScaledSize(selectedSize, badgeSelectionConfig.badgeSideLength * window.devicePixelRatio);
                    badgesLoadedPromises = profile.badges.map(function (badgeDefinition) {
                        var badgeCanvas = document.querySelector("canvas[x-badge='" + badgeDefinition.slug + "'");
                        if (badgeCanvas == null) {
                            console.error('Matching canvas for badge not found', { badgeDefinition: badgeDefinition, badgeCanvas: badgeCanvas });
                            return;
                        }
                        return buildPreviewFor(badgeCanvas, canvasSize, badgeDefinition, badgeImageHolder.getBadgeImageFor(badgeDefinition), placeholderImageLoaded);
                    });
                    return [4 /*yield*/, Promise.all(badgesLoadedPromises)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function buildPreviewFor(canvas, canvasSize, badgeDefinition, badgeImagePromise, placeholderImagePromise) {
    return __awaiter(this, void 0, void 0, function () {
        var placeholderImage, badgeImage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    canvas.width = canvasSize.width;
                    canvas.height = canvasSize.height;
                    return [4 /*yield*/, placeholderImagePromise];
                case 1:
                    placeholderImage = _a.sent();
                    return [4 /*yield*/, badgeImagePromise];
                case 2:
                    badgeImage = _a.sent();
                    drawImageWithBadge(canvas, placeholderImage, badgeImage, badgeDefinition, null);
                    if (hasCustomText(badgeDefinition)) {
                        addInfoPopupForCustomTextTo(canvas);
                    }
                    return [2 /*return*/];
            }
        });
    });
}
// Tab upload
//
var uploadConfig = CONFIG.tabs.upload;
var cameraConfig = uploadConfig.camera;
var userImage = document.getElementById('user-image');
var imageLoader = document.getElementById('image-loader');
var openCameraPickerButton = document.getElementById('open-camera-picker');
var uploadSuccess = document.getElementById('upload-success');
var renderedBadgeForPicker = document.createElement('canvas');
var uploadedWithCameraPick = false;
var cameraModal = new CameraModal();
imageLoader.addEventListener('change', imageBrowsed, false);
addEventListenerWhileDisabled(openCameraPickerButton, 'click', pickImageFromCamera);
removeCameraPickChoiceIfCameraPickIsNotPossible().then(function () { });
Wizard.onLoad('upload', function () {
    if (userImage.src === '') {
        Wizard.disableNext();
    }
    else {
        Wizard.enableNext();
    }
});
function imageBrowsed() {
    var _a;
    var reader = new FileReader();
    reader.onload = function (event) {
        var _a;
        uploadSuccess.style.display = 'inline';
        var dataUrl = (_a = event.target) === null || _a === void 0 ? void 0 : _a.result;
        if (dataUrl == null)
            return;
        userImage.src = dataUrl;
        uploadedWithCameraPick = false;
        Wizard.enableNext();
        sendEvent('pick_user_image', {
            method: 'file'
        });
    };
    var file = (_a = this.files) === null || _a === void 0 ? void 0 : _a[0];
    if (file != null) {
        reader.readAsDataURL(file);
    }
}
function pickImageFromCamera() {
    return __awaiter(this, void 0, void 0, function () {
        var badge, canvas, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    badge = getBadgeWithCorrectSize(selectedBadge);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, cameraModal.pickFromCamera(badge, selectedSize)];
                case 2:
                    canvas = _a.sent();
                    if (canvas == null) {
                        return [2 /*return*/];
                    }
                    sendEvent('pick_user_image', {
                        method: 'camera'
                    });
                    uploadSuccess.style.display = 'inline';
                    userImage.src = canvas.toDataURL();
                    uploadedWithCameraPick = true;
                    Wizard.enableNext();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Got error from "pickFromCamera":', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function removeCameraPickChoiceIfCameraPickIsNotPossible() {
    return __awaiter(this, void 0, void 0, function () {
        var withCameraOnly;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, canPickWithCamera()];
                case 1:
                    if (_a.sent()) {
                        return [2 /*return*/];
                    }
                    withCameraOnly = document.querySelectorAll('.with-camera-only');
                    withCameraOnly.forEach(function (child) {
                        child.remove();
                    });
                    return [2 /*return*/];
            }
        });
    });
}
function getBadgeWithCorrectSize(badge) {
    var renderedBadgeSize = calculateScaledSize(selectedSize, cameraConfig.badgeSideLength * window.devicePixelRatio);
    renderedBadgeForPicker.width = renderedBadgeSize.width;
    renderedBadgeForPicker.height = renderedBadgeSize.height;
    drawImageWithBadge(renderedBadgeForPicker, null, selectedBadgeImage, badge, null);
    return renderedBadgeForPicker;
}
Wizard.onNext('upload', function (e) {
    if (uploadedWithCameraPick && cameraConfig.skipCropping) {
        croppedUserImage = userImage;
        Wizard.goTo(5).then(function () { });
        e.cancel = true;
    }
});
// Tab crop
//
var cropConfig = CONFIG.tabs.crop;
var cropImage = document.getElementById('crop-image');
var customTextInput = document.getElementById('custom-text-input');
var cropper = null;
var croppedUserImage = null;
var renderedBadge = setupRenderedBadge();
Wizard.onNext('upload', function () {
    if (cropper != null) {
        cropper.destroy();
        cropper = null;
    }
    cropImage.src = userImage.src;
});
Wizard.onLoad('crop', function () { return __awaiter(void 0, void 0, void 0, function () {
    var renderedBadgeSize;
    return __generator(this, function (_a) {
        if (cropper != null) {
            cropper.destroy();
            cropper = null;
        }
        if (selectedSize == null)
            throw new Error('Cropping loaded without a selected size');
        renderedBadgeSize = calculateScaledSize(selectedSize, cropConfig.badgeSideLength * window.devicePixelRatio);
        renderedBadge.width = renderedBadgeSize.width;
        renderedBadge.height = renderedBadgeSize.height;
        drawImageWithBadge(renderedBadge, null, selectedBadgeImage, selectedBadge, null);
        cropper = new Cropper(cropImage, {
            // See npmjs.com/package/cropperjs#options
            aspectRatio: selectedSize.width / selectedSize.height,
            viewMode: 1,
            dragMode: 'move',
            center: false,
            guides: false,
            background: false,
            autoCropArea: 1,
            ready: function () {
                var cropperViewBox = document.querySelector('.cropper-view-box');
                if (cropperViewBox == null) {
                    throw new Error('Missing cropper view box');
                }
                cropperViewBox.appendChild(renderedBadge);
            }
        });
        return [2 /*return*/];
    });
}); });
Wizard.onNext('crop', function () {
    croppedUserImage = cropper.getCroppedCanvas();
});
eventEmitter.on('badge-selected', function (_a) {
    var definition = _a.definition;
    if (hasCustomText(definition)) {
        customTextInput.classList.add('show');
        customTextInput.placeholder = definition.customTexts[0].placeholder;
    }
    else {
        customTextInput.classList.remove('show');
        customTextInput.placeholder = '';
    }
});
customTextInput.addEventListener('input', debounce(function () {
    var customText = customTextInput.value;
    customTexts = [customText];
    drawImageWithBadge(renderedBadge, null, selectedBadgeImage, selectedBadge, customTexts);
}, cropConfig.customTextRenderDelayMs));
function setupRenderedBadge() {
    var canvas = document.createElement('canvas');
    canvas.style.pointerEvents = 'none';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.position = 'absolute';
    canvas.style.left = '0';
    canvas.style.top = '0';
    return canvas;
}
// Tab generate
//
var renderedImageCanvas = document.createElement('canvas');
var renderedImage = document.getElementById('rendered-image');
Wizard.onNext('size-selection', function () {
    if (selectedSize == null)
        throw new Error('Size selection is done but no size was selected');
    renderedImageCanvas.width = selectedSize.width;
    renderedImageCanvas.height = selectedSize.height;
});
Wizard.onLoad('generate', function () { return __awaiter(void 0, void 0, void 0, function () {
    var selectedBadgeImage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, badgeImageHolder.getBadgeImageFor(selectedBadge)];
            case 1:
                selectedBadgeImage = _a.sent();
                drawImageWithBadge(renderedImageCanvas, croppedUserImage, selectedBadgeImage, selectedBadge, customTexts);
                document.dispatchEvent(new CustomEvent('imageRendered', {
                    detail: {
                        image: renderedImageCanvas
                    }
                }));
                renderedImage.src = renderedImageCanvas.toDataURL();
                return [2 /*return*/];
        }
    });
}); });
var generateConfig = CONFIG.tabs.generate;
var generateProgressContainer = document.getElementById('generate-progress-container');
var generateProgressBar = new ProgressBar(document.getElementById('generate-progress-bar'));
var generationSpeed = {
    interval: 50,
    baseStep: 0.005,
    randomStepRange: 0.01
};
var generationRequired = true;
Wizard.onLoad('generate', function () {
    if (!generationRequired) {
        return;
    }
    generateProgressContainer.classList.remove('done');
    generateProgressBar.value = 0;
    Wizard.disableNext();
    var interval = setInterval(function () { return __awaiter(void 0, void 0, void 0, function () {
        var previousValue, newValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    previousValue = generateProgressBar.value;
                    newValue = randomStep(previousValue);
                    if (!(newValue >= 1)) return [3 /*break*/, 2];
                    newValue = 1;
                    return [4 /*yield*/, finish()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    generateProgressBar.value = newValue;
                    return [2 /*return*/];
            }
        });
    }); }, generationSpeed.interval);
    function finish() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clearInterval(interval);
                        Wizard.enableNext();
                        generationRequired = false;
                        generateProgressContainer.classList.add('done');
                        return [4 /*yield*/, Wizard.next()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function randomStep(previous) {
        var randomProgress = (Math.random() % (generationSpeed.randomStepRange * 100)) / 100;
        var totalProgress = generationSpeed.baseStep + randomProgress;
        var scaledProgress = totalProgress * generateConfig.speedModifier;
        return previous + scaledProgress;
    }
});
Wizard.onPrevious('generate', function () {
    Wizard.enableNext();
});
Wizard.onNext('crop', function () {
    generationRequired = true;
});
// Tab download
//
var downloadConfig = CONFIG.tabs.download;
var downloadButton = document.getElementById('download-button');
var shareButton = document.getElementById('share-button');
var shareFacebookButton = document.getElementById('share-facebook-button');
var shareFacebookError = document.getElementById('share-facebook-error');
var backToStartButton = document.getElementById('back-to-start-button');
var uploadProgressBar = new ProgressBar(document.getElementById('upload-progress-bar'));
var nativeShareService = new NativeShareService();
var facebookShareService = new FacebookShareService(renderedImageCanvas, uploadProgressBar);
if (iOS()) {
    downloadButton.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, growAndShrinkWithText(renderedImage, 'Hier lang drücken, um das Bild herunterzuladen!', { placement: 'top' })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
}
else {
    downloadButton.addEventListener('click', function () {
        downloadRenderedImage();
    });
    renderedImage.addEventListener('click', function () {
        downloadRenderedImage();
    });
}
Wizard.onPrevious('generate', function () {
    facebookShareService.invalidateUploaded();
});
shareFacebookButton.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () {
    var error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                shareFacebookError.classList.remove('show');
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, facebookShareService.share(selectedBadge, customTexts)];
            case 2:
                _a.sent();
                sendEvent('share', {
                    method: 'Facebook',
                    content_type: 'image',
                    content_id: selectedBadge.slug
                });
                return [3 /*break*/, 4];
            case 3:
                error_2 = _a.sent();
                if (error_2 instanceof FacebookBlockedError) {
                    shareFacebookError.classList.add('show');
                }
                else {
                    console.error('Could not share:', error_2);
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); });
backToStartButton.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Wizard.goTo(0)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); });
document.addEventListener('imageRendered', function (e) {
    prepareNativeShare(e).then(function () { });
});
shareButton.addEventListener('click', function (e) {
    e.preventDefault();
    nativeShareService.share().catch(function (error) {
        console.error('Native share failed', error);
    });
    sendEvent('share', {
        method: 'Native',
        content_type: 'image',
        content_id: selectedBadge.slug
    });
});
function downloadRenderedImage() {
    var url = renderedImage.src;
    var _a = getTypeAndNameForDownload(), name = _a.name, type = _a.type;
    download(url, name, type);
    sendEvent('download', {
        content_type: 'image'
    });
}
function getTypeAndNameForDownload() {
    var _a;
    var defaultFormat = downloadConfig.defaultFormat;
    var originalFile = (_a = imageLoader === null || imageLoader === void 0 ? void 0 : imageLoader.files) === null || _a === void 0 ? void 0 : _a[0];
    return {
        name: (originalFile === null || originalFile === void 0 ? void 0 : originalFile.name) || defaultFormat.name,
        type: (originalFile === null || originalFile === void 0 ? void 0 : originalFile.type) || defaultFormat.type
    };
}
function prepareNativeShare(event) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var image, originalFile, shareDetails, canShare;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    image = event.detail.image;
                    originalFile = (_b = (_a = imageLoader.files) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : null;
                    shareDetails = getSharingDetails(selectedBadge, customTexts);
                    return [4 /*yield*/, nativeShareService.prepareShare(image, originalFile, shareDetails)];
                case 1:
                    _c.sent();
                    return [4 /*yield*/, nativeShareService.canShare()];
                case 2:
                    canShare = _c.sent();
                    if (canShare) {
                        shareButton.classList.remove('collapse');
                    }
                    else {
                        shareButton.classList.add('collapse');
                    }
                    return [2 /*return*/];
            }
        });
    });
}
// General
//
function forceSelectionForContinue(tabName, radio) {
    Wizard.onLoad(tabName, function () {
        if (radio.value == null) {
            Wizard.disableNext();
        }
        else {
            Wizard.enableNext();
        }
    });
    Wizard.onPrevious(tabName, function () {
        Wizard.enableNext();
    });
    radio.events.on('valueChanged', function (newValue) {
        if (newValue == null) {
            Wizard.disableNext();
        }
        else {
            Wizard.enableNext();
        }
    });
}
